
/* width */
#chat-messages::-webkit-scrollbar {
  width: 6.5px;
}

/* Track */
#chat-messages::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
#chat-messages::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

/* Handle on hover */
#chat-messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}